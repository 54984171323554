import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default function Section2() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);
  return (
    <div className='bg-white py-5'>
      <div className='flex justify-center gap-5'>
        <div data-aos="fade-right">
          <p className='text-black text-4xl lg:text-7xl text-center font-marcellus'>SUCCESS </p>
        </div>
        <div data-aos="fade-left">
          <p className='text-black text-4xl lg:text-7xl text-center font-marcellus'>STORY</p>
        </div>
      </div>
      <div className='flex flex-col lg:flex-row justify-center py-10 px-10' >
        <div className='lg:w-[50%]'>
          <div className="relative flex justify-center items-center mt-10 mb-10">

            <img
              src="/assets/Landing/KP_192 3.svg"
              alt="First"
              className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 rotate-6  absolute"
            />


            <img
              src="/assets/Landing/KP_137 2.svg"
              alt="Second"
              className="w-8/12 sm:w-6/12 md:w-5/12 lg:w-8/12 -rotate-6  relative"
              data-aos='fade-up' data-aos-duration='2000'
            />
          </div>
        </div>
        <div className='lg:w-[50%]'>
          <p className='text-black font-roboto lg:text-3xl' >Having spent years navigating the world of entrepreneurship,
            I've learned that true success comes from not just leading businesses
            but transforming them with innovative strategies and hands-on leadership mentoring.  </p>
        </div>
      </div>
    </div>
  )
}
