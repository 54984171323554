import { React, useEffect, useState, useRef } from "react";
import Radio from "@mui/material/Radio"; import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import toast from "react-hot-toast";
import { SERVER_URL } from "../../../config";
const Section6 = () => {
  const countries = [
    "India",
    "United States",
    "Canada",
    "Australia",
    "United Kingdom",
    "Germany",
    "France",
  ];

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    additionalInfo: "",
    country: "",
  });
  const [errors, setErrors] = useState({});
  const [videoFile, setVideoFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        toast.error("File size must be less than 20MB");
        return;
      }
      setVideoFile(file);
    }
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size must be less than 5MB");
        return;
      }
      setImageFile(file);
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }
    if (!formData.additionalInfo.trim()) {
      newErrors.additionalInfo = "Additional information is required";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = new FormData();
      Object.keys(formData).forEach((key) => data.append(key, formData[key]));
      if (imageFile) data.append("image", imageFile);
      if (videoFile) data.append("video", videoFile);

      try {
        toast.loading("Please wait while File uploading is in progress", {
          duration: 1000
        })
        const response = await axios.post(`${SERVER_URL}/api/testimonials`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("Response:", response.data);
        toast.success("Form submitted successfully!");
        setTimeout(() => {
          setIspopupopen(false);
        }, 3000)
      } catch (error) {
        console.error("Error submitting the form:", error);
        toast.error("An error occurred.");
      }
    } else {
      console.log("Validation errors", errors);
    }
  };


  const [isopenpopup, setIspopupopen] = useState(false);

  const handlepopup = () => {
    setIspopupopen(!isopenpopup);
  };



  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: "20px",
        },
      },
    ],
  };
  const [selectedValue, setSelectedValue] = useState("a");
  const intervalRef = useRef(null);
  const videoDurations = {
    a: 46,
    b: 35,
    c: 35,
    d: 31,
    e: 35,
  };
  const ids = ["a", "b", "c", "d", "e"];

  const handleChanges = (event) => {
    clearTimeout(intervalRef.current);
    setSelectedValue(event.target.value);
    startAutoLoop(event.target.value);
  };

  const startAutoLoop = (current = selectedValue) => {
    const duration = videoDurations[current] || 30;
    intervalRef.current = setTimeout(() => {
      setSelectedValue((prev) => {
        const currentIndex = ids.indexOf(prev);
        const nextIndex = (currentIndex + 1) % ids.length;
        startAutoLoop(ids[nextIndex]);
        return ids[nextIndex];
      });
    }, duration * 1000);
  };

  useEffect(() => {
    startAutoLoop();
    return () => clearTimeout(intervalRef.current);
  }, []);

  const handleCancel = () => {
    setVideoFile(null);
  };



  const handleCancel2 = () => {
    setImageFile(null);
  };

  return (

    <div className="w-full bg-[#FCF7F7]  flex flex-col justify-center items-center font-roboto pt-5">
      <div className="w-[85%] mx-auto flex flex-col justify-center items-center gap-10 md:gap-0">
        <h1 className="font-marcellus text-4xl lg:text-7xl font-medium ">
          Testimonials
        </h1>
      </div>
      <div className="w-full ">
        <Slider {...settings} className=" w-[100%]">
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FFBA6EED] rounded-lg  ">
              <iframe
                width="100%"
                height="500"
                src="/assets/Videos/Testimonial 01.mp4"
                title="Video Player 5"
                frameBorder="0"
                allow="autoplay; encrypted-media; picture-in-picture"
                style={{ borderRadius: "15px", overflow: "hidden" }}
              ></iframe>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-gradient-to-b from-[#FF6061C7] to-[#F98517C7] rounded-lg  ">
              <iframe
                width="100%"
                height="500"
                src="/assets/Videos/Testimonial 02.mp4"
                title="Video Player 5"
                frameBorder="0"
                allow=" encrypted-media; picture-in-picture"
                style={{ borderRadius: "15px", overflow: "hidden" }}
              ></iframe>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FF6061] rounded-lg  h-full  ">
              <iframe
                width="100%"
                height="500"
                src="/assets/Videos/Testimonial 03.mp4"
                title="Video Player 5"
                frameBorder="0"
                allow=" encrypted-media; picture-in-picture"
                style={{ borderRadius: "15px", overflow: "hidden" }}
              ></iframe>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FF6061] rounded-lg  h-full  ">
              <iframe
                width="100%"
                height="500"
                src="/assets/Videos/Testimonial 04.mp4"
                title="Video Player 5"
                frameBorder="0"
                allow=" encrypted-media; picture-in-picture"
                style={{ borderRadius: "15px", overflow: "hidden" }}
              ></iframe>
            </div>
          </div>
          <div className=" md:px-5 px-2 h-full ">
            <div className=" bg-[#FF6061] rounded-lg  h-full  ">
              <iframe
                width="100%"
                height="500"
                src="/assets/Videos/Testimonial 05.mp4"
                title="Video Player 5"
                frameBorder="0"
                allow=" encrypted-media; picture-in-picture"
                style={{ borderRadius: "15px", overflow: "hidden" }}
              ></iframe>
            </div>
          </div>
        </Slider>
      </div>
      <div className=" flex justify-center mt-10 ">
        <button
          onClick={handlepopup}
          className=" px-10 font-semibold py-3 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-lg text-sm text-white text-center "
        >
          Click Here To Submit Yours
        </button>
      </div>

      {isopenpopup && (
        <div className="w-full fixed inset-0 flex justify-center items-center bg-black/50 z-50 px-5 ">
          <div className="h-[600px] sm:absolute top-10 lg:top-3 px-5 overflow-auto lg:overflow-hidden">
            <div className=" bg-[#EAEAEA] p-10 ">
              <div>
                <button
                  className="w-full text-right text-black rounded-md font-semibold "
                  onClick={handlepopup}
                >
                  x
                </button>
              </div>
              <div className="flex items-center gap-5">
                <label className="cursor-pointer relative">
                  {imageFile ? (
                    <div>
                      <button
                        className="w-full text-left text-black rounded-md font-semibold "
                        onClick={handleCancel2}
                      >
                        x
                      </button>
                      <img
                        src={imageFile}
                        alt="Uploaded Preview"
                        className="rounded-full w-24"
                      />
                    </div>
                  ) : (
                    <img
                      src="/assets/Landing/user8.png"
                      className="rounded-full w-24"
                      alt="Default Profile"
                    />
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleFileChange2}
                  />
                </label>
                <div>
                  <p className="text-lg font-bold">Upload Image</p>
                  <p className="text-sm">
                    File smaller than 5 MB at least 400px by 400px
                  </p>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="mt-10">
                <div className="flex md:flex-row flex-col  gap-5">
                  <div className="md:w-1/2 w-full">
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="placeholder:text-black placeholder:font-bold w-full border border-black bg-transparent rounded-md py-3 px-4"
                      placeholder="First Name"
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-sm">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="md:w-1/2 w-full">
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="placeholder:text-black placeholder:font-bold w-full border border-black bg-transparent rounded-md py-3 px-4"
                      placeholder="Last Name"
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-sm">{errors.lastName}</p>
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  gap-5 mt-5">
                  <div className="md:w-1/2 w-full">
                    <p className='border border-black rounded-md px-4 flex justify-between'>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="placeholder:text-black placeholder:font-bold w-full  bg-transparent rounded-md py-3"
                        placeholder="Email"
                      />
                      <img src='/assets/Icons/mail.svg'></img>
                    </p>

                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                  <div className="md:w-1/2 w-full">
                    <p className='border border-black rounded-md px-4 flex justify-between'>
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="placeholder:text-black placeholder:font-bold w-full  bg-transparent rounded-md py-3 "
                        placeholder="Phone Number"
                      />
                      <img src='/assets/Icons/phone.svg'></img>
                    </p>

                    {errors.phone && (
                      <p className="text-red-500 text-sm">{errors.phone}</p>
                    )}
                  </div>
                </div>

                <div className="flex md:flex-row flex-col  items-center gap-5 mt-5">
                  <div className="md:w-1/2 w-full">
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className="w-full placeholder:text-black placeholder:font-bold border border-black bg-transparent rounded-md py-3 px-4"
                    >
                      <option value="" >Select Country</option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <p className="text-red-500 text-sm">{errors.country}</p>
                    )}
                  </div>
                  <div className="md:w-1/2 w-full flex items-center gap-5">
                    <label className=" w-full cursor-pointer">
                      {videoFile ? (
                        <div>
                          <button
                            className=" text-black text-right w-full rounded-md font-semibold "
                            onClick={handleCancel}
                          >
                            x
                          </button>
                          <video
                            className="w-44 rounded"
                            controls
                            src={videoFile}
                            alt="Uploaded Video Preview"
                          />
                        </div>
                      ) : (
                        <div className=" flex justify-center items-center">
                          <div className="relative flex justify-center items-center">
                            <img
                              src="/assets/Landing/user8.png"
                              className="w-28"
                              alt="Profile"
                            />
                            <div className="absolute bg-black/40 rounded-full w-7 h-7 flex justify-center items-center">
                              <i
                                className="fa-solid fa-play fa-sm"
                                style={{ color: "#FFFFFF" }}
                              ></i>
                            </div>
                          </div>
                          <div>
                            <p className=" font-bold">
                              Upload Video <span>(optional)</span>
                            </p>
                            <p className="text-xs">
                              File smaller than 20 MB at least 400px by 400px
                            </p>
                          </div>
                        </div>
                      )}

                      <input
                        type="file"
                        accept="video/*"
                        className="hidden"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>

                <div className="mt-5">
                  <textarea
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleChange}
                    className="w-full placeholder:text-black placeholder:font-bold border border-black bg-transparent rounded-md py-3 px-4"
                    placeholder="Additional Information"
                  ></textarea>
                  {errors.additionalInfo && (
                    <p className="text-red-500 text-sm">
                      {errors.additionalInfo}
                    </p>
                  )}
                </div>

                <div className="flex justify-center mt-5">
                  <button
                    type="submit"
                    className="px-14 font-semibold py-2.5 shadow-md shadow-black/25 bg-gradient-to-r from-[#F2534F] to-[#FA8F3C] rounded-xl text-sm text-white text-center"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Section6;
